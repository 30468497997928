<template>
  <div>
    <a-modal
      :visible="visible"
      title="审批通过"
      :width="480"
      :confirmLoading="confirmLoading"
      :destroyOnClose="true"
      :maskClosable="false"
      @cancel="handleCancel"
      @ok="handleConfirm"
    >
      <a-form :form="dataForm" :label-col="{ span: 6 }" :wrapper-col="{ span: 18 }">
        <a-form-item label="备注" :label-col="{ span: 3 }" :wrapper-col="{ span: 21 }">
          <a-textarea v-decorator="['remark', { rules: [{ max: 256, message: '超出最大长度(256)' }] }]" :allowClear="true" />
        </a-form-item>
      </a-form>
    </a-modal>
  </div>
</template>

<script>
import { approvalTaskApprove } from "@/api/approval";

export default {
  props: ["visible", "item"],
  model: { prop: "visible", event: "cancel" },
  data() {
    return {
      confirmLoading: false,
      dataForm: null,
    };
  },
  methods: {
    handleConfirm() {
      this.dataForm.validateFields((error, values) => {
        if (error === null) {
          this.confirmLoading = true;
          approvalTaskApprove({ id: this.item.id, ...values })
            .then(() => {
              this.$emit("confirm");
              this.$message.success("通过成功");
              this.handleCancel();
            })
            .finally(() => {
              this.confirmLoading = false;
            });
        }
      });
    },
    handleCancel() {
      this.$emit("cancel", false);
    },
  },
  mounted() {
    this.dataForm = this.$form.createForm(this);
  },
};
</script>

<style scoped></style>
